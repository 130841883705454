$overlay-menu-width: auto;
$overlay-menu-button-height: 3rem;
$overlay-menu-button-height-mobile: 2rem;
$overlay-footer-height: 6rem;

.container-tab-component {
  div {
    width: fit-content;
  }

  .k-button--tab {
    position: relative;
    height: $overlay-menu-button-height;
    border-radius: 0%;
    border: none;
  }

  .k-button--tab-active {
    font-weight: bold !important;
  }
  .k-button--tab-active::after {
    content: '';
    bottom: -5px !important;
    top: unset;
    color: #406b8a !important;
    height: 4px !important;
    opacity: 1;
    border-radius: 0%;
  }
}
