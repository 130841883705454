.stepper {
  display: flex;
  align-items: center;
  &__step {
    flex-grow: 1;
    display: flex;
    align-items: center;
    color: $cg-blue;
    &:before,
    &:after {
      content: '';
      flex: 1;
      border-bottom: 1px solid #9ebdca;
    }
    &:first-of-type::before,
    &:last-of-type::after {
      border: none;
    }
  }
}
