.layout.layout--errors {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;

  h1 {
    color: $cg-green;
  }

  .k-button-solid-primary {
    border-color: $cg-green;
    background-color: $cg-green;
    &:hover {
      border-color: #0f9169;
      background-color: #109c72;
    }
    &:focus {
      box-shadow: 0 0 0px 0.25rem rgba(20, 191, 139, 0.5);
    }
  }

  .layout__left,
  .layout__right {
    display: grid;
    align-items: center;
    justify-content: center;
  }

  .layout__left {
    background-color: white;
    overflow-y: auto;
    padding-left: 1rem;
    padding-right: 1rem;

    .container-xxl,
    .container-xl,
    .container-lg,
    .container-md,
    .container-sm,
    .container {
      max-width: 500px;
    }
  }

  .layout__right {
    background-color: $base-bg;
    .logo {
      width: 10vw;
    }
  }
}

@include media-breakpoint-down(md) {
  .layout.layout--authentication {
    grid-template-columns: 1fr;
    .layout__right {
      display: none;
    }
  }
}
