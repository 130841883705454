﻿.assessment-container {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  .player-header {
    background-color: rgba(var(--bs-dark-rgb), 1) !important;
  }

  .assessment-content {
    height: 100%;
    overflow-y: auto;
  }

  .assessment-average-rate {
    height: 4rem;
    border-radius: 4px;
  }

  .assessmentQR {
    height: 100%;
  }
}

.assessment-dropdown-players {
  width: 10rem;
}

.assessment-action-content,
.assessment-vote-content {
  margin-bottom: 1rem;
  height: 42px !important;
}

.assessment-action-content {
  line-height: '3.5' !important;
}

.assessment-export {
  width: 80%;
  overflow-x: hidden;
  overflow-y: auto;
}

.score-rounded {
  width: 2rem;
  height: 2rem;
  pointer-events: none;
}

.waiting-modal {
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  z-index: 1020;

  .waiting-content {
    // margin-top: -300px;
  }
}

.rating-container {
  margin-right: 2px;
  margin-top: 2px;
  cursor: pointer;
  border-width: 0;

  .rating-container-body {
    background-color: rgba(var(--bs-success-rgb), 0.2) !important;
    width: 2rem;
    height: 2rem;
  }
}

@media (min-width: 767px) {
  .rating-container {
    margin-right: 2px;
    margin-top: 2px;
    cursor: pointer;
    border-width: 0;

    .rating-container-body {
      background-color: rgba(var(--bs-success-rgb), 0.2) !important;
      width: 3rem;
      height: 3rem;
    }
  }
}

​.input-group {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.input-group .form-group {
  flex: 1;
}

@media (max-width: 767px) {
  .input-group .form-group {
    flex: 0 0 100%;
    margin-bottom: 10px;
  }
}

.success_bg-opacity-20 {
  background-color: rgba(var(--bs-success-rgb), 0.2) !important;
}

.primary_bg-opacity-20 {
  background-color: #f8fcfe;
}

@media (max-width: 767px) {
  .card.success_bg-opacity-20.vote .card-body {
    flex-wrap: wrap;
    justify-content: flex-start !important;
  }

  .card.success_bg-opacity-20.vote .card-body > span,
  .card-body > div {
    flex-basis: 100%;
  }

  .card.success_bg-opacity-20.vote .card-body > div {
    justify-content: end !important;
  }

  .card.success_bg-opacity-20.vote {
    background: #d2f3ea !important;
    border: none !important;
    padding: 0 !important;
  }

  .card.success_bg-opacity-20.vote .card-body > span {
    background: rgba(var(--bs-success-rgb), 0.2) !important;
    padding: 20px;
    margin-bottom: 10px;
  }
}

.assessment-container + .container {
  margin-bottom: 20px;
}

.k-card-header.scan {
  z-index: 0;
}
