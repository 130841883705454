.map-canvas {
  position: relative;
}

.map {
  width: 100%;
  height: 90vh;
  position: absolute;
  top: 90px;
}

.map--wrapper {
  width: 100%;
  height: 100vh;
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  filter: blur(3px);
  position: absolute;
}

.map-task-content {
  height: auto;
  background-color: $body-bg;
  z-index: 2;
}

.task-content {
  margin-top: 6rem;
}

.map-view-option {
  position: absolute;
  z-index: 10;
  bottom: 10px;
  left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  background-color: #ffffff;
  border-radius: 3px;
  span {
    font-size: 14px;
    margin-right: 5px;
  }
}

.map-pad {
  position: absolute;
  border-radius: 100%;
  overflow: hidden;
  top: 6.5rem;
  right: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  transform: rotate(-45deg);
  transform-origin: center;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 1px;
    width: 100%;
    background-color: $subtle-text;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    width: 0.8px;
    background-color: $subtle-text;
  }

  button {
    border: none;
    background-color: #fff;
    padding: 0;
    height: 2rem;
    width: 2rem;

    &:nth-child(1) {
      align-items: flex-end;
      justify-content: flex-end;

      span {
        transform: rotate(315deg) translate(-2px, 7px);
      }
    }

    &:nth-child(2) {
      align-items: flex-end;
      justify-content: flex-start;

      span {
        transform: rotate(45deg) translate(3px, 7px);
      }
    }

    &:nth-child(3) {
      align-items: flex-start;
      justify-content: flex-end;

      span {
        transform: rotate(225deg) translate(-3px, 2px);
      }
    }

    &:nth-child(4) {
      align-items: flex-start;
      justify-content: flex-start;

      span {
        transform: rotate(135deg) translate(2px, 2px);
      }
    }
  }
}

.map-reset-rotate {
  position: absolute;
  overflow: hidden;
  background-color: #80808040;
  top: 6.5rem;
  left: 60px;
}
