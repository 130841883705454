.k-drawer.k-drawer-end {
  background-color: #003a64d9;
  color: white;

  .k-drawer-item {
    border-radius: 8px;
    margin: 2px 0;
  }

  .k-drawer-item.k-selected {
    background-color: white;
    color: #003a64;
  }
}
