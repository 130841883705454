@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

$kendo-enable-color-system: false;

$cg-green: #14bf8b;
$cg-blue: #4698cb;
$cg-red: #d22630;

:root {
  --cg-green: $cg-green;
  --cg-blue: $cg-blue;
  --cg-red: $cg-red;
}

// Override Bootstrap BaseFont
$font-family-sans-serif: 'Open Sans', sans-serif;

$border-radius: 0.25rem;
$btn-border-radius-lg: $border-radius;
$dark-theme: false;
$primary: #4698cb;
$secondary: #a8b6be;
$info: #0dc5e8;
$success: #14bf8b;
$warning: #feb333;
$error: #d22630;
$body-text: #1d252d;
$body-bg: #ffffff;
$dark: #003a64;
$headings-text: #1d252d;
$subtle-text: #9fb1bc;
$disabled-text: #8f8f8f;
$component-text: #1d252d;
$component-bg: #ffffff;
$base-text: #1d252d;
$base-bg: #f8f9fa;
$hovered-text: #1d252d;
$hovered-bg: #e9ecef;
$selected-text: #ffffff;
$selected-bg: #4698cb;
$kendo-button-text: #003a64;
$kendo-button-bg: #e4e7eb;
$link-text: #4698cb;
$link-hover-text: #d22630;
$series-a: #4698cb;
$series-b: #5f62af;
$series-c: #20c997;
$series-d: #ff2474;
$series-e: #feb333;
$series-f: #ff5527;

$body-color: #003a64;

//hide validation icon in bootstrap input form
$enable-validation-icons: false;

//stepper bullet size
$stepper-indicator-width: 15px;
$stepper-indicator-height: 15px;
$stepper-indicator-border: $primary;

// Input button
$input-border-color: $primary;
$input-border-radius-lg: $border-radius;

$h1-font-size: 1.375rem;
$h2-font-size: 1.25rem;
$h3-font-size: 1rem;
$h4-font-size: 0.875rem;
$h5-font-size: 0.75rem;

$kendo-checkbox-checked-bg: #14bf8b;
$kendo-checkbox-checked-text: white;

$kendo-switch-on-track-bg: #14bf8b;
$kendo-switch-on-thumb-bg: white;

$hairline-color: #e9e9e9;

.spinner {
  position: absolute;
  z-index: 99999999 !important;
}
.spin {
  animation: 25s linear infinite spinner-border;
}

// a loader animation to show while loading component
.loader {
  width: 2.313rem;
  height: 2.313rem;
  border: 0.188rem solid $body-color;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  &:after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 2.813rem;
    height: 2.813rem;
    border-radius: 50%;
    border: 0.188rem solid;
    border-color: $primary transparent;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@import 'bootstrap/scss/bootstrap.scss';
@import '~@progress/kendo-theme-bootstrap/dist/all.scss';

@import 'components/all';
@import 'layouts/all';
@import 'vendor/all';
@import 'pages/all';

.mask {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100dvw;
  height: 100dvh;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  align-items: center;
}
