$card-background: #f8fcfe;
$card-title-color: #194153;
$card-subtitle-color: #5da4d2;

.card-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100%;
  max-height: 100%;
  &__card {
    display: flex;
    flex-direction: column;
    .card__inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 1rem;
      background-color: $card-background;
      border-radius: 1rem;
      .card__image {
        width: 7rem;
        height: 7rem;
        background-size: cover;
        background-position: center center;
        border-radius: 50%;
        box-shadow: 0px 3px 6px #00000029;
      }
      .card__title,
      .card__sub-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .card__title {
        font-weight: bold;
        font-size: 1rem;
        margin-top: 0.5rem;
        color: $card-title-color;
      }
      .card__sub-title {
        font-size: 0.75rem;
        color: $card-subtitle-color;
      }
    }
    &.card-list__card--new {
      .card__inner {
        animation-name: new-card;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-direction: alternate;
      }
    }
    .card__body {
      display: flex;
      flex-direction: column;
    }
  }
}

@keyframes new-card {
  from {
    background-color: $card-background;
  }
  to {
    background-color: darken($card-background, 5%);
  }
}
