.algorithm-editor {
  .add-step {
  }

  .step-editor {
    font-size: 0.8rem;

    &.step-editor--control {
      .control__if .selector__description {
        padding-left: 1rem;
      }
    }

    .branch-icon {
      color: $series-b;
    }

    .step-editor__selector {
      .badge {
        font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono,
          Courier New, monospace;
        font-weight: normal;
        font-size: 0.8rem;
        background-color: $kendo-button-bg;
        color: $series-b;
      }

      .dropdown-item {
        font-weight: normal;
        font-size: 0.8rem;
      }

      &.identifier--select,
      &.argument--select,
      &.condition--select,
      &.operation--select,
      &.argument--select {
        .badge {
          background-color: $series-b;
          color: #ffffff;
        }
      }

      &.step-editor__selector--identifier {
        &.identifier--player .badge {
        }
      }

      &.step-editor__selector--condition {
        &.condition--does .badge {
          background-color: rgba($success, 0.25);
        }
        &.condition--doesNot .badge {
          background-color: rgba($danger, 0.25);
        }
      }

      &.step-editor__selector--operation {
        &.operation--select .badge {
        }
      }

      &.step-editor__selector--argument {
        &.argument--select .badge {
        }
      }

      .selector__description {
      }
    }
  }
}

.form-event-control-inline {
  font-size: 0.8rem;
  display: inline-block;
  width: 4rem;
  padding: 0.1rem 0 0.1rem 0.3rem;
}
