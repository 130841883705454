.markdown-container {
  max-height: calc(100vh - 420px);

  img {
    max-width: 100%;
    height: auto;
  }
}

.answer-container {
  img {
    max-width: 100%;
    height: 200px;
  }
}

.task-center {
  margin: 0 auto;
}

.video-record-action {
  width: 400px;
  position: absolute;
  bottom: 20px;
  height: 70px;
  left: calc(50% - 200px);
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 6px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.video-icon {
  border: none;
  background: transparent;
  cursor: pointer;
  &:active,
  &:focus,
  &:hover {
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow: none;
  }
  span {
    font-size: 50px;
  }

  &.right-position {
    left: auto;
    right: 0;
  }
}

.camera-action {
  width: 400px;
  position: absolute;
  bottom: 20px;
  height: 70px;
  left: calc(50% - 200px);
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 6px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  border: 1px solid rgba(255, 255, 255, 0.3);

  .switch-icon {
    cursor: pointer;
    font-size: 50px;
  }
}

.task-content-media {
  width: 400px;
  height: 250px;
  border-width: 0px;

  .media-image-container {
    height: auto;

    .media-image {
      max-width: 100%;
      height: 250px;
      width: auto;
    }
  }
  .k-scrollview-prev,
  .k-scrollview-next {
    height: 48px;
  }
}

.webcam-modal {
  .k-dialog-wrapper {
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    padding: 1rem;
  }

  .k-dialog {
    border: none;
    background: transparent;
    box-shadow: none;
    width: 100% !important;
    max-width: 1366px !important;
    height: 90vh !important;
    margin: 0;

    .k-dialog-content {
      padding: 0;
      background: transparent;
      height: 100%;
    }
  }

  .webcam-container {
    position: relative;
    height: 100%;

    .camera-controls {
      z-index: 2;

      button {
        background: rgba(255, 255, 255, 0.2);
        border: none;
        color: white;

        &:hover {
          background: rgba(255, 255, 255, 0.3);
        }
      }
    }

    .camera-actions {
      z-index: 2;

      button {
        width: 64px;
        height: 64px;
        background: #fff;
        border: 3px solid #ddd;

        &:hover {
          background: #f8f8f8;
        }

        span {
          font-size: 24px;
        }
      }
    }
  }
}

.image-preview-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;

  .preview-image {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    object-fit: contain;
  }
}
