$app-side-nav-background-color: #f8fcfe !default;
$app-side-nav-width: 16rem !default;
$app-side-nav-width-collapsed: 3rem !default;

.app-side-nav {
  width: $app-side-nav-width;
  background-color: $app-side-nav-background-color;
  padding-top: 1.875rem;
  transition: width 0.3s;

  .nav-header {
    display: flex;
    flex-direction: row;
    margin-bottom: 22px;

    .hamburger {
      width: $app-side-nav-width-collapsed;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &.app-side-nav--collapsed {
    width: $app-side-nav-width-collapsed;

    .nav-header {
      flex-direction: column-reverse;
      margin-bottom: 0;

      .hamburger {
        height: $app-side-nav-width-collapsed;
      }
    }
  }

  hr {
    background-color: $secondary;
    margin: 0 0.5rem;
  }

  .aside-menu {
    display: flex;
    flex-direction: column;

    .aside-menu__item {
      display: flex;
      flex-grow: 1;
      overflow: hidden;
      align-items: center;
      box-sizing: border-box;
      border: none;
      background-color: transparent;
      margin: 0;
      font-weight: 600;
      font-size: 0.75rem;
      color: $body-color;

      &:hover {
        background-color: $hovered-bg;
      }

      .item__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: $app-side-nav-width-collapsed;
        height: $app-side-nav-width-collapsed;
        flex-grow: 0;
        flex-shrink: 0;
      }

      .item__label {
        flex-grow: 1;
        text-align: left;
        padding-left: 0.25rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-weight: normal;
      }

      .icon-container {
        min-width: 1.25rem;
        min-height: 1.25rem;

        display: flex;
        align-items: center;
      }

      &.active {
        color: $primary;
      }
    }

    .aside-menu__sub {
      &.aside-menu__sub--expanded {
      }
      &.aside-menu__sub--collapsed {
        display: none;
      }
      .aside-menu__item {
        .item__icon {
          .material-symbols-outlined {
            font-size: 18px;
          }
        }
      }
    }

    .aside-menu__parent {
      color: #003a64 !important;
    }
  }

  a {
    color: $success;
    text-decoration: none;
  }

  .aside-menu__footer {
    margin-top: auto;
    overflow-x: hidden;
  }
}
