#root {
  overflow: auto !important;
}

.layout--feedback {
  height: 100%;
  ::-webkit-scrollbar {
    width: 0px; /* Width of the scrollbar */
  }
  .app-main {
    height: 100%;
  }
}

.layout-feedback {
  padding-left: 1vh;
  padding-right: 1vh;
}

.layout-feedback-form {
  height: 100%;
}

.button-skip-continue {
  font-size: 12px !important;
  color: #003a64;
  font-weight: bold;
}

.layout-feedback-form__main {
  height: 80vh;
  overflow: auto;

  .feedback-title {
    font-size: 16px;
    font-weight: bold;
  }

  .feedback-question-container {
    width: 70%;
    .feedback-question {
      font-size: 16px;
      font-weight: bold;
    }
  }

  @media only screen and (max-width: 767px) {
    .feedback-question-container {
      width: 100%;
    }
  }

  .feedback-answer-container {
    width: 60%;
  }

  .feedback-answer-container label {
    color: #003a64;
  }

  @media only screen and (max-width: 767px) {
    .feedback-answer-container {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 767px) {
  .layout-feedback-form__main img {
    width: 100%;
    height: auto;
  }
}

.overlay-header-feedback {
  background-color: rgba(#003a64, 0.85);
  color: white;
}

.feedback-header-pill {
  display: grid;
  grid-template-columns: max-content 1fr;
  background-color: rgba(#242424, 0.5);
  border-radius: 50rem;
  padding-right: 40px;

  .feedback-header-pill__progress {
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    padding: 0.25rem;
    margin-right: 0.5rem;
  }

  .feedback-header-pill__image {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    box-shadow: 0px 3px 6px #00000029;
    background-color: #ffffff;
  }
  .feedback-header-pill__title,
  .feedback-header-pill__subtitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .feedback-header-pill__title {
    color: #ffffff;
    font-size: 24px;
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    align-content: center;
  }

  .feedback-header-pill__subtitle {
    font-size: 1rem;
    font-weight: bold;
    color: #ff5527;
  }
}

@media screen and (max-width: 767px) {
  .feedback-header-pill {
    background: none !important;
  }
}
