.media {
  position: relative;

  &__main {
    width: 100%;
    min-height: 20rem;
    max-height: 20rem;
    position: relative;

    img,
    video {
      min-height: 20rem;
      max-height: 20rem;
      width: 100%;
      border-radius: 0.35rem;
    }
    video {
      background-color: rgb(21, 21, 22);
    }
  }

  &__thumbnail {
    display: flex;
    align-items: center;
    @extend .gap-2;

    .slide {
      height: 4rem;
      cursor: pointer;

      img,
      video {
        min-height: 4rem;
        max-height: 4rem;
        width: 4rem;
        border-radius: 0.35rem;
        position: relative;
        overflow: hidden;
      }

      video {
        background-color: rgb(21, 21, 22);
      }

      &.active {
        opacity: 1;
      }
    }
  }
}
