/* Popup style */
.popup-box {
  position: fixed;
  z-index: 100022;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(#000, 0.26);
}

.k-animation-container {
  z-index: 10003; // set the desired value based on the application requirements
}

.box {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30vw;
  height: auto;
  max-height: 85vh;
  transform: translate(-50%, -50%);
  background: #fff;
  color: #18181c;
  border-radius: 0.35rem;
  padding: 1rem;
  z-index: 100023;

  @media screen and (min-width: 769px) and (max-width: 992px) {
    width: calc(100vw - 450px);
  }

  @media screen and (min-width: 577px) and (max-width: 768px) {
    width: calc(100vw - 375px);
  }

  @media screen and (max-width: 576px) {
    width: calc(100vw - 30px);
  }
}
