.layout--wizard {
  background-color: $kendo-button-text;
}
.wizard--progress {
  display: flex;
  gap: 60px;
  align-items: center;
  justify-content: center;
  margin-left: 0;
  padding-left: 0;
  li {
    list-style: none;
    background-color: $white;
    border: 1px solid $primary;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    position: relative;

    &:after {
      content: ' ';
      position: absolute;
      background-color: $primary;
      width: 70px;
      height: 1px;
      right: -70px;
      top: 6px;
    }

    &.active {
      background-color: $primary;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }
}
