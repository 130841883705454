.layout.layout--in-game {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

$overlay-menu-height: 6rem;
$overlay-menu-height-mobile: 5rem;
$overlay-menu-button-height: 4rem;
$overlay-menu-button-height-mobile: 4rem;
$overlay-footer-height: 1rem;

.layout.layout--in-game .layout__container {
  .game__canvas {
    height: 100vh;
    width: 100vw;
    overflow: hidden;

    .canvas__map {
      height: 100%;
      width: 100%;
    }

    .task-content__mask {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      height: 100%;
      width: 100%;
      padding: 1rem;
      background-color: rgba(black, 0.5);
      backdrop-filter: blur(2px);
      z-index: 1;

      .task-content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        background-color: white;
        width: 100%;
        max-width: 1366px;
        border-radius: $border-radius;

        .content__header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 24px;
        }

        .content__body {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          overflow-y: auto;
          padding: 16px 24px;
          gap: 4px;
        }

        .content__footer {
          padding: 0 24px;
          .content {
            padding: 16px 0;
          }
        }
      }
    }

    .ol-control:last-of-type {
      margin-top: 6rem;
    }

    .ol-control {
      padding-left: 0.5rem;
      button {
        border-radius: $border-radius;
        border: 1px solid #dcdfe3;
        background-color: white;
        width: 2rem;
        height: 2rem;
        box-shadow: $box-shadow-sm;
        &:hover {
          background-color: $hovered-bg;
        }
      }
      &.ol-zoom .ol-zoom-in {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      &.ol-zoom .ol-zoom-out {
        border-left: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      &.ol-rotate {
        padding-top: 0.25rem;
        button {
        }
      }
    }

    .overlay-debug {
      z-index: 1;
    }
  }

  .assessment__canvas,
  .game__overlay {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    // overflow: hidden;
  }

  .assessment__canvas {
    background-position: center center;
    background-size: cover;
    bottom: 0;
    overflow-y: auto;
    // margin-top: 8rem;
  }

  .assessment__canvas {
    margin-top: 0 !important;
  }

  .game__overlay {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: $overlay-menu-height 1fr $overlay-footer-height;
    transition: height ease 0.5s;
    // max-height: 100vh;

    .overlay__menu,
    .overlay__drawer {
      position: relative;
      -webkit-transition: background 0.5s ease;
      -moz-transition: background 0.5s ease;
      -o-transition: background 0.5s ease;
      transition: background 0.5s ease;

      .overlay__timer {
        position: absolute;
        width: 100%;
        height: 100%;
      }
    }

    &--active {
      height: 100%;
      .overlay__menu {
        z-index: 1021; // need more higher than sticky-top bootstrap
      }
      .overlay__menu,
      .overlay__drawer {
        background-color: rgba(#003a64, 0.85);
      }
    }

    &--in-active {
      height: $overlay-menu-height;
      .overlay__menu {
        z-index: unset; // parent set z-index : unset to handle issues in iOS & WebKit
      }
      .overlay__menu,
      .overlay__drawer {
        background-color: rgba(#003a64, 0.2);
      }
    }

    .overlay__menu {
      display: flex;
      align-items: center;
    }
    .overlay__drawer {
      z-index: 1020; // need more higher than sticky-top bootstrap
    }
  }

  .header-left-menu {
    float: left;
    width: 50%;
    justify-content: start;
  }
  .header-right-menu {
    float: right;
    width: 50%;
    justify-content: end;
  }
}

.overlay__drawer {
  display: grid;
  grid-template-rows: min-content 1fr min-content;
  overflow: hidden;
  padding-right: 24px;
  padding-left: 24px;
  .drawer__tabs {
    max-width: 1366px;

    .k-button--tab {
      height: $overlay-menu-button-height;
      margin-top: 0.5rem;
      margin-right: 0.5rem;
      .k-button-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: white;
        font-weight: bold;
      }
      svg {
        max-width: 100%;
        height: 2.5rem;
        margin-bottom: 0.25rem;
      }
    }
    .k-button--tab-active::after {
      content: '';
      bottom: -5px;
      top: unset;
      color: #ff5527 !important;
      height: 4px;
      opacity: 1;
    }
    .container-menu-button {
      display: flex;

      .icon {
        font-size: 40px;
      }

      .label {
        font-weight: 400;
      }
    }
    .k-button-flat {
      height: auto;
    }
  }
  .drawer__body {
    max-width: 1366px;
    overflow-y: auto;
    background-color: white;
    position: relative;
    margin-top: 1rem;
    .col-loader {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: 0;
    }
  }
  .drawer__footer {
    max-width: 1366px;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

@media screen and (max-width: 767px) {
  .game__canvas {
    .task-content__mask {
      padding-top: 0 !important;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .k-button--tab {
    height: $overlay-menu-button-height-mobile;
    svg {
      height: 1.5rem;
      margin-bottom: 0.25rem;
    }
  }
  .overlay__tabs {
    margin-top: 2rem;
  }
  .container-menu-button {
    display: flex;
  }
  .k-button-flat {
    height: auto !important;
  }
}

@media screen and (min-width: 481px) and (max-width: 767px) {
  .k-button--tab {
    height: $overlay-menu-button-height-mobile !important;
  }

  .k-button--tab-active::after {
    bottom: -25px !important;
  }

  .container-menu-button {
    display: flex;
  }
}

@media screen and (min-width: 459px) and (max-width: 480px) {
  .k-button--tab {
    height: $overlay-menu-button-height-mobile;
    svg {
      height: 2rem !important;
      margin-bottom: 0.25rem !important;
    }
  }

  .k-button--tab-active::after {
    bottom: -20px !important;
  }

  .container-menu-button {
    padding: 3rem;
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 2rem;
  }

  .k-button-lg {
    padding-inline: 0.5rem;
  }

  .layout.layout--in-game .layout__container .header-left-menu {
    width: auto !important;
  }

  .overflow-hidden.container-header.row {
    flex-wrap: nowrap !important;
  }

  .header-right-menu
    .k-button.k-button-lg.k-button-flat.k-button-flat-primary.k-rounded-md.text-white {
    padding-inline-end: 6px !important;
    padding-inline-start: 6px !important;
  }

  .header-right-menu
    .k-button.k-button-lg.k-button-flat.k-button-flat-primary.k-rounded-md.text-white
    .material-symbols-outlined {
    font-size: 24px !important;
  }

  .progress-pill.progress-pil__game-title {
    background: none !important;
  }

  .progress-pill.progress-pil__game-title > div:last-child {
    display: none !important;
  }

  .progress-pill.progress-pil__game-title {
    background: none !important;
  }

  .progress-pill.progress-pil__game-title > div:last-child {
    display: none !important;
  }

  .header-left-menu .progress-pill .progress-pill__progress {
    width: 3rem;
    height: 3rem;
    margin-top: 0.5rem;
  }

  .header-left-menu .progress-pill .progress-pill__title {
    font-size: 0.7rem;
  }

  .header-left-menu .progress-pill .progress-pill__subtitle {
    font-size: 0.8rem;
  }
}

@media screen and (min-width: 379px) and (max-width: 458px) {
  .k-button--tab {
    height: $overlay-menu-button-height-mobile;
    svg {
      max-width: 100%;
      height: 2rem !important;
      margin-bottom: 0.25rem !important;
    }
  }

  .k-button--tab-active::after {
    bottom: -15px !important;
  }

  .k-button-flat {
    height: 2rem !important;
  }

  .container-menu-button {
    padding: 2rem;
    display: grid;
    grid-template-columns: auto auto auto auto;

    .k-button-lg {
      padding-inline: 0.3rem;
    }
    justify-content: center;
    gap: 1.6rem;
  }

  .header-right-menu
    .k-button.k-button-lg.k-button-flat.k-button-flat-primary.k-rounded-md.text-white {
    padding-inline-end: 6px !important;
    padding-inline-start: 6px !important;
  }

  .header-right-menu
    .k-button.k-button-lg.k-button-flat.k-button-flat-primary.k-rounded-md.text-white
    .material-symbols-outlined {
    font-size: 24px !important;
  }

  .game__overlay .progress-pill {
    padding-right: 10px;
  }

  .progress-pill.progress-pil__game-title {
    background: none !important;
  }

  .progress-pill.progress-pil__game-title > div:last-child {
    display: none !important;
  }

  .progress-pill.progress-pil__game-title {
    background: none !important;
  }

  .progress-pill.progress-pil__game-title > div:last-child {
    display: none !important;
  }

  .header-left-menu .progress-pill .progress-pill__progress {
    width: 3rem;
    height: 3rem;
    margin-top: 0.5rem;
  }

  .header-left-menu .progress-pill .progress-pill__title {
    font-size: 0.7rem;
  }

  .header-left-menu .progress-pill .progress-pill__subtitle {
    font-size: 0.8rem;
  }
}

@media screen and (min-width: 341px) and (max-width: 378px) {
  .k-button--tab {
    height: $overlay-menu-button-height-mobile;
    svg {
      max-width: 100%;
      height: 1.5rem !important;
      margin-bottom: 0.25rem !important;
    }
  }

  .k-button--tab-active::after {
    bottom: -15px !important;
  }

  .k-button-flat {
    height: 2rem !important;
  }

  .container-menu-button {
    padding: 1rem;
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 1.5rem;
  }

  .k-button-lg {
    padding-inline: 0.5rem;
  }

  .header-right-menu
    .k-button.k-button-lg.k-button-flat.k-button-flat-primary.k-rounded-md.text-white {
    padding-inline-end: 6px !important;
    padding-inline-start: 6px !important;
  }

  .header-right-menu
    .k-button.k-button-lg.k-button-flat.k-button-flat-primary.k-rounded-md.text-white
    .material-symbols-outlined {
    font-size: 24px !important;
  }

  .game__overlay .progress-pill {
    padding-right: 10px;
  }

  .progress-pill.progress-pil__game-title {
    background: none !important;
  }

  .progress-pill.progress-pil__game-title > div:last-child {
    display: none !important;
  }

  .progress-pill.progress-pil__game-title {
    background: none !important;
  }

  .progress-pill.progress-pil__game-title > div:last-child {
    display: none !important;
  }

  .header-left-menu .progress-pill .progress-pill__progress {
    width: 3rem;
    height: 3rem;
    margin-top: 0.5rem;
  }

  .header-left-menu .progress-pill .progress-pill__title {
    font-size: 0.7rem;
  }

  .header-left-menu .progress-pill .progress-pill__subtitle {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 340px) {
  .overlay__drawer {
    height: 20rem !important;
  }

  .overlay__menu > div {
    padding: 0px;
  }

  .k-button--tab {
    height: $overlay-menu-button-height-mobile;
    svg {
      max-width: 100%;
      height: 1.5rem !important;
      margin-bottom: 0.25rem !important;
    }
  }

  .k-button--tab-active::after {
    bottom: -15px !important;
  }

  .k-button-flat {
    height: 2rem !important;
  }

  .container-menu-button {
    padding: 0.25rem;
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 1rem;
  }

  .k-button-lg {
    padding-inline: 0.5rem;
  }

  .header-right-menu
    .k-button.k-button-lg.k-button-flat.k-button-flat-primary.k-rounded-md.text-white {
    padding-inline-end: 6px !important;
    padding-inline-start: 6px !important;
  }

  .header-right-menu
    .k-button.k-button-lg.k-button-flat.k-button-flat-primary.k-rounded-md.text-white
    .material-symbols-outlined {
    font-size: 24px !important;
  }

  .game__overlay .progress-pill {
    padding-right: 10px;
  }

  .progress-pill.progress-pil__game-title {
    background: none !important;
  }

  .progress-pill.progress-pil__game-title > div:last-child {
    display: none !important;
  }

  .header-left-menu .progress-pill .progress-pill__progress {
    width: 3rem;
    height: 3rem;
    margin-top: 0.5rem;
  }

  .header-left-menu .progress-pill .progress-pill__title {
    font-size: 0.7rem;
  }

  .header-left-menu .progress-pill .progress-pill__subtitle {
    font-size: 0.8rem;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .game__canvas {
    .ol-control:last-of-type {
      margin-top: 5rem !important;
    }

    .map-pad,
    .map-reset-rotate {
      top: 5.5rem !important;
    }
  }

  .layout.layout--in-game .layout__container .game__overlay {
    grid-template-rows: $overlay-menu-height-mobile 1fr $overlay-footer-height !important;
  }

  .drawer-mobile-profile.k-button-flat.material-symbols-outlined,
  .header-right-menu
    .k-button.k-button-lg.k-button-flat.k-button-flat-primary.k-rounded-md.text-white
    .material-symbols-outlined {
    font-size: 24px !important;
  }

  .drawer-mobile-profile {
    .k-avatar {
      height: 50px;
      width: 50px;
    }
  }

  .overlay__drawer {
    padding-left: 16px;
    padding-right: 16px;

    .drawer__body {
      margin-top: 0px;
    }
  }

  .video-playback-wrapper {
    max-width: 100%;
  }

  #answer-webcam,
  video {
    width: 100%;
    height: 100%;
  }
}

// Moto G Mobile Media Screen
@media only screen and (max-width: 640px) and (orientation: landscape) {
  .map-task-content {
    max-height: max-content !important;
  }

  .map-task-content {
    overflow-y: unset !important;
    overflow-x: unset !important;
  }

  .map-task-content .content__footer {
    position: relative !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) and (orientation: landscape) {
  .pre-message-dialog {
    width: 35rem !important;
    height: 18rem !important;
    margin-top: 3rem !important;
  }

  .pre-message-dialog .pre-message-dialog__body {
    padding-top: 2rem !important;
  }

  .pre-message-dialog .pre-message-dialog__body div {
    padding-bottom: 0.1 rem !important;
  }

  .pre-message-dialog .pre-message-dialog__body video {
    height: 15rem !important;
  }

  .layout.layout--in-game .layout__container .game__overlay {
    grid-template-rows: $overlay-menu-height 1fr $overlay-footer-height !important;
  }

  .markdown-container {
    max-height: calc(100vh - 265px) !important;
  }

  .layout.layout--in-game .layout__container .game__overlay--in-active {
    height: 3rem;
  }

  .header-right-menu
    .k-button.k-button-lg.k-button-flat.k-button-flat-primary.k-rounded-md.text-white
    .material-symbols-outlined {
    font-size: 32px !important;
  }

  .layout.layout--in-game
    .layout__container
    .game__overlay.game__overlay--active
    .overlay__drawer {
    z-index: 1020;
    height: 85vh;
  }

  .layout.layout--in-game
    .layout__container
    .game__overlay.game__overlay--active
    .overlay__drawer
    .row:first-child
    div {
    height: 14vh;
  }

  .overlay__drawer .drawer__tabs .container-menu-button .icon {
    font-size: 40px;
  }

  .achievement-dialog .achievement-dialog__body {
    padding: 3rem 1rem 1rem 1rem !important;
    height: 20rem !important;
  }

  .achievement-dialog .achievement-dialog__image {
    width: 6rem !important;
    height: 6rem !important;
    margin-bottom: -3rem !important;
  }
}

@media screen and (min-width: 481px) and (max-width: 767px) and (orientation: landscape) {
  .layout.layout--in-game .layout__container .game__overlay {
    grid-template-rows: $overlay-menu-height-mobile 1fr $overlay-footer-height !important;
  }

  .pre-message-dialog {
    width: 35rem !important;
    height: 18rem !important;
    margin-top: 3rem !important;
  }

  .pre-message-dialog .pre-message-dialog__body video {
    height: 15rem !important;
  }

  .pre-message-dialog .pre-message-dialog__body {
    padding-top: 2rem !important;
  }

  .pre-message-dialog .pre-message-dialog__body div {
    padding-bottom: 0.1 rem !important;
  }

  .pre-message-dialog .pre-message-dialog__body video {
    height: 15rem !important;
  }

  .layout.layout--in-game .layout__container .game__overlay {
    grid-template-rows: 6rem 1fr $overlay-footer-height !important;
  }

  .markdown-container {
    max-height: calc(100vh - 265px) !important;
  }

  .layout.layout--in-game .layout__container .game__overlay--in-active {
    height: 3rem;
  }

  .game__overlay.game__overlay--active .overlay__menu .container-header {
    height: 3rem !important;
  }

  .header-right-menu
    .k-button.k-button-lg.k-button-flat.k-button-flat-primary.k-rounded-md.text-white
    .material-symbols-outlined {
    font-size: 24px !important;
  }

  .layout.layout--in-game
    .layout__container
    .game__overlay.game__overlay--active
    .overlay__drawer {
    z-index: 1020;
    height: 85vh;
  }

  .layout.layout--in-game
    .layout__container
    .game__overlay.game__overlay--active
    .overlay__drawer
    .row:first-child
    div {
    height: 14vh;
  }

  .overlay__drawer .drawer__tabs .container-menu-button .icon {
    font-size: 20px;
  }

  .achievement-dialog .achievement-dialog__body {
    padding: 3rem 1rem 1rem 1rem !important;
    height: 20rem !important;
  }

  .achievement-dialog .achievement-dialog__image {
    width: 6rem !important;
    height: 6rem !important;
    margin-bottom: -3rem !important;
  }
}

@media screen and (min-width: 459px) and (max-width: 480px) and (orientation: landscape) {
  .layout.layout--in-game .layout__container .game__overlay {
    grid-template-rows: $overlay-menu-height-mobile 1fr $overlay-footer-height !important;
  }

  .pre-message-dialog {
    width: 35rem !important;
    height: 18rem !important;
    margin-top: 3rem !important;
  }

  .pre-message-dialog .pre-message-dialog__body video {
    height: 15rem !important;
  }

  .pre-message-dialog .pre-message-dialog__body {
    padding-top: 2rem !important;
  }

  .pre-message-dialog .pre-message-dialog__body div {
    padding-bottom: 0.1 rem !important;
  }

  .pre-message-dialog .pre-message-dialog__body video {
    height: 15rem !important;
  }

  .layout.layout--in-game .layout__container .game__overlay {
    grid-template-rows: 4rem 1fr $overlay-footer-height !important;
  }

  .markdown-container {
    max-height: calc(100vh - 265px) !important;
  }

  .layout.layout--in-game .layout__container .game__overlay--in-active {
    height: 3rem;
  }

  .game__overlay.game__overlay--active .overlay__menu .container-header {
    height: 3rem !important;
  }

  .header-right-menu
    .k-button.k-button-lg.k-button-flat.k-button-flat-primary.k-rounded-md.text-white
    .material-symbols-outlined {
    font-size: 24px !important;
  }

  .layout.layout--in-game
    .layout__container
    .game__overlay.game__overlay--active
    .overlay__drawer {
    z-index: 1020;
    height: 85vh;
  }

  .layout.layout--in-game
    .layout__container
    .game__overlay.game__overlay--active
    .overlay__drawer
    .row:first-child
    div {
    height: 14vh;
  }

  .overlay__drawer .drawer__tabs .container-menu-button .icon {
    font-size: 20px;
  }

  .achievement-dialog .achievement-dialog__body {
    padding: 3rem 1rem 1rem 1rem !important;
    height: 20rem !important;
  }

  .achievement-dialog .achievement-dialog__image {
    width: 6rem !important;
    height: 6rem !important;
    margin-bottom: -3rem !important;
  }
}

@media screen and (min-width: 379px) and (max-width: 458px) and (orientation: landscape) {
  .layout.layout--in-game .layout__container .game__overlay {
    grid-template-rows: $overlay-menu-height-mobile 1fr $overlay-footer-height !important;
  }

  .pre-message-dialog {
    width: 35rem !important;
    height: 18rem !important;
    margin-top: 3rem !important;
  }

  .pre-message-dialog .pre-message-dialog__body video {
    height: 15rem !important;
  }

  .pre-message-dialog .pre-message-dialog__body {
    padding-top: 2rem !important;
  }

  .pre-message-dialog .pre-message-dialog__body div {
    padding-bottom: 0.1 rem !important;
  }

  .pre-message-dialog .pre-message-dialog__body video {
    height: 15rem !important;
  }

  .layout.layout--in-game .layout__container .game__overlay {
    grid-template-rows: 4rem 1fr 1rem !important;
  }

  .markdown-container {
    max-height: calc(100vh - 265px) !important;
  }

  .layout.layout--in-game .layout__container .game__overlay--in-active {
    height: 2rem;
  }

  .game__overlay.game__overlay--active .overlay__menu .container-header {
    height: 3rem !important;
  }

  .header-right-menu
    .k-button.k-button-lg.k-button-flat.k-button-flat-primary.k-rounded-md.text-white
    .material-symbols-outlined {
    font-size: 24px !important;
  }

  .layout.layout--in-game
    .layout__container
    .game__overlay.game__overlay--active
    .overlay__drawer {
    z-index: 1020;
    height: 85vh;
  }

  .layout.layout--in-game
    .layout__container
    .game__overlay.game__overlay--active
    .overlay__drawer
    .row:first-child
    div {
    height: 14vh;
  }

  .overlay__drawer .drawer__tabs .container-menu-button .icon {
    font-size: 20px;
  }

  .achievement-dialog .achievement-dialog__body {
    padding: 3rem 1rem 1rem 1rem !important;
    height: 20rem !important;
  }

  .achievement-dialog .achievement-dialog__image {
    width: 6rem !important;
    height: 6rem !important;
    margin-bottom: -3rem !important;
  }
}

@media screen and (min-width: 341px) and (max-width: 378px) and (orientation: landscape) {
  .layout.layout--in-game .layout__container .game__overlay {
    grid-template-rows: $overlay-menu-height-mobile 1fr $overlay-footer-height !important;
  }

  .pre-message-dialog {
    width: 35rem !important;
    height: 18rem !important;
    margin-top: 3rem !important;
  }

  .pre-message-dialog .pre-message-dialog__body video {
    height: 15rem !important;
  }

  .pre-message-dialog .pre-message-dialog__body {
    padding-top: 2rem !important;
  }

  .pre-message-dialog .pre-message-dialog__body div {
    padding-bottom: 0.1 rem !important;
  }

  .pre-message-dialog .pre-message-dialog__body video {
    height: 15rem !important;
  }

  .layout.layout--in-game .layout__container .game__overlay {
    grid-template-rows: 4rem 1fr $overlay-footer-height !important;
  }

  .markdown-container {
    max-height: calc(100vh - 265px) !important;
  }

  .layout.layout--in-game .layout__container .game__overlay--in-active {
    height: 3rem;
  }

  .game__overlay.game__overlay--active .overlay__menu .container-header {
    height: 3rem !important;
  }

  .header-right-menu
    .k-button.k-button-lg.k-button-flat.k-button-flat-primary.k-rounded-md.text-white
    .material-symbols-outlined {
    font-size: 24px !important;
  }

  .layout.layout--in-game
    .layout__container
    .game__overlay.game__overlay--active
    .overlay__drawer {
    z-index: 1020;
    height: 85vh;
  }

  .layout.layout--in-game
    .layout__container
    .game__overlay.game__overlay--active
    .overlay__drawer
    .row:first-child
    div {
    height: 14vh;
  }

  .overlay__drawer .drawer__tabs .container-menu-button .icon {
    font-size: 20px;
  }

  .achievement-dialog .achievement-dialog__body {
    padding: 3rem 1rem 1rem 1rem !important;
    height: 20rem !important;
  }

  .achievement-dialog .achievement-dialog__image {
    width: 6rem !important;
    height: 6rem !important;
    margin-bottom: -3rem !important;
  }
}

@media screen and (min-width: 341px) and (max-width: 378px) and (orientation: landscape) {
  .layout.layout--in-game .layout__container .game__overlay {
    grid-template-rows: $overlay-menu-height-mobile 1fr $overlay-footer-height !important;
  }

  .pre-message-dialog {
    width: 35rem !important;
    height: 18rem !important;
    margin-top: 3rem !important;
  }

  .pre-message-dialog .pre-message-dialog__body video {
    height: 15rem !important;
  }

  .pre-message-dialog .pre-message-dialog__body {
    padding-top: 2rem !important;
  }

  .pre-message-dialog .pre-message-dialog__body div {
    padding-bottom: 0.1 rem !important;
  }

  .pre-message-dialog .pre-message-dialog__body video {
    height: 15rem !important;
  }

  .layout.layout--in-game .layout__container .game__overlay {
    grid-template-rows: 4rem 1fr $overlay-footer-height !important;
  }

  .markdown-container {
    max-height: calc(100vh - 265px) !important;
  }

  .layout.layout--in-game .layout__container .game__overlay--in-active {
    height: 3rem;
  }

  .game__overlay.game__overlay--active .overlay__menu .container-header {
    height: 3rem !important;
  }

  .header-right-menu
    .k-button.k-button-lg.k-button-flat.k-button-flat-primary.k-rounded-md.text-white
    .material-symbols-outlined {
    font-size: 24px !important;
  }

  .layout.layout--in-game
    .layout__container
    .game__overlay.game__overlay--active
    .overlay__drawer {
    z-index: 1020;
    height: 85vh;
  }

  .layout.layout--in-game
    .layout__container
    .game__overlay.game__overlay--active
    .overlay__drawer
    .row:first-child
    div {
    height: 14vh;
  }

  .overlay__drawer .drawer__tabs .container-menu-button .icon {
    font-size: 20px;
  }

  .achievement-dialog .achievement-dialog__body {
    padding: 3rem 1rem 1rem 1rem !important;
    height: 20rem !important;
  }

  .achievement-dialog .achievement-dialog__image {
    width: 6rem !important;
    height: 6rem !important;
    margin-bottom: -3rem !important;
  }
}

@media screen and (max-width: 340px) and (orientation: landscape) {
  .layout.layout--in-game .layout__container .game__overlay {
    grid-template-rows: $overlay-menu-height-mobile 1fr $overlay-footer-height !important;
  }

  .pre-message-dialog {
    width: 35rem !important;
    height: 18rem !important;
    margin-top: 3rem !important;
  }

  .pre-message-dialog .pre-message-dialog__body video {
    height: 15rem !important;
  }

  .pre-message-dialog .pre-message-dialog__body {
    padding-top: 2rem !important;
  }

  .pre-message-dialog .pre-message-dialog__body div {
    padding-bottom: 0.1 rem !important;
  }

  .pre-message-dialog .pre-message-dialog__body video {
    height: 15rem !important;
  }

  .layout.layout--in-game .layout__container .game__overlay {
    grid-template-rows: 4rem 1fr $overlay-footer-height !important;
  }

  .markdown-container {
    max-height: calc(100vh - 265px) !important;
  }

  .layout.layout--in-game .layout__container .game__overlay--in-active {
    height: 3rem;
  }

  .game__overlay.game__overlay--active .overlay__menu .container-header {
    height: 3rem !important;
  }

  .header-right-menu
    .k-button.k-button-lg.k-button-flat.k-button-flat-primary.k-rounded-md.text-white
    .material-symbols-outlined {
    font-size: 24px !important;
  }

  .layout.layout--in-game
    .layout__container
    .game__overlay.game__overlay--active
    .overlay__drawer {
    z-index: 1020;
    height: 85vh;
  }

  .layout.layout--in-game
    .layout__container
    .game__overlay.game__overlay--active
    .overlay__drawer
    .row:first-child
    div {
    height: 14vh;
  }

  .overlay__drawer .drawer__tabs .container-menu-button .icon {
    font-size: 20px;
  }

  .achievement-dialog .achievement-dialog__body {
    padding: 3rem 1rem 1rem 1rem !important;
    height: 20rem !important;
  }

  .achievement-dialog .achievement-dialog__image {
    width: 6rem !important;
    height: 6rem !important;
    margin-bottom: -3rem !important;
  }
}

.disabled-answer input {
  pointer-events: none;
}

.notification-dialog {
  position: absolute;
  top: 8rem;
  left: 0;
  right: 0;
  bottom: 2rem;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  color: #153259;
  background-color: white;
  z-index: 2;

  .push-content img {
    max-width: 100% !important;
    height: auto !important;
    object-fit: contain !important;
  }
}

.player-container {
  background-color: rgba(255, 255, 255, 0.8);
  height: fit-content;

  &.on-illustration {
    font-size: 12px !important;

    .avatar-container {
      padding: 4px !important;

      .avatar-img {
        height: 25px !important;
        width: 25px !important;
      }
    }
  }
}

.player-container,
.task-container {
  display: flex;
  transition: all 0.125s;
  border-radius: 50px;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .avatar-container,
  .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;

    .avatar-circle {
      background-color: #4698cb;
    }

    .avatar-circle,
    .icon-circle {
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: end;

      .avatar-img,
      .icon-img {
        height: 50px;
        width: 50px;
        object-fit: contain;
        border-radius: 100%;
      }
    }
  }

  .profile-container,
  .detail-task-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: opacity 0.125s;
    transition-delay: 0.25s;
    transition-property: opacity;
  }

  .profile-container.visible,
  .detail-task-container.visible {
    display: flex;
    opacity: 1;
  }

  .profile-container.hidden,
  .detail-task-container.hidden {
    display: none;
    opacity: 0;
  }
}

.task-container.initial {
  border: 0px;

  .icon-container {
    padding: 0px;
  }
}

.task-container.enable-click {
  cursor: pointer;
}

.player-container.expanded,
.task-container.expanded {
  gap: 4px;
  padding-right: 30px;
  border: 2px solid #003a67;
  background-color: rgba(255, 255, 255, 0.8);
}

.player-container.highlighted {
  border: 3px solid $cg-green;
}

.player-container.blip {
  animation: blip-animation 0.5s ease-in-out;
  animation-delay: 2.5s;
}

@keyframes blip-animation {
  0% {
    background-color: rgba(255, 255, 255, 0.8);
  }
  50% {
    background-color: $cg-green;
  }
  100% {
    background-color: rgba(255, 255, 255, 0.8);
  }
}

.other-players-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background-color: rgba(255, 255, 255, 0.8);
  height: 33px;
  width: 33px;
  transition:
    width 0.125s ease-out,
    height 0.125s ease-out;
  border-radius: 100%;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px;
  font-size: 12px;

  .other-players-item {
    display: flex;
    width: 100%;
    text-wrap: wrap;
    text-align: left;

    .avatar-container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-right: 8px;

      .avatar-circle {
        height: 25px;
        width: 25px;
        border-radius: 100%;
        background-color: #4698cb;
        display: flex;
        justify-content: center;
        align-items: end;

        .avatar-img {
          height: 25px;
          width: 25px;
          object-fit: contain;
          border-radius: 100%;
        }
      }
    }

    .profile-container {
      display: flex;
      max-width: 100%;
      word-break: break-all;
      flex-direction: column;
      justify-content: center;
      align-items: start;
      gap: 0px;
    }
  }
}

.other-players-container.expanded {
  width: 150px;
  height: 150px;
  justify-content: initial;
  align-items: initial;
  flex-direction: column;
  border-radius: 10px !important;
  border: 2px solid #003a67;
}

.drawer__body,
.facilitator-chat-container {
  .k-chat {
    max-width: none;
  }
}
