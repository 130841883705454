@use 'sass:math';

$cursors: (
  'pointer': 'pointer',
  'drag': 'drag',
  'progress': 'progress',
  'none': 'none',
  'help': 'help',
  'wait': 'wait'
);

$spaces: (
  '0': '0px',
  '1': '0.25rem',
  '2': '0.5rem',
  '3': '0.75rem',
  '4': '1rem',
  '5': '1.25rem',
  '6': '1.5rem',
  '7': '1.75rem',
  '8': '2rem',
  '9': '2.25rem',
  '10': '2.5rem',
  '11': '3rem',
  '12': '3.25rem',
  '14': '3.5rem',
  '16': '3.75rem',
  '18': '4rem',
  '20': '4.25rem'
);

$opacities: (
  '0': '0',
  '01': '0.1',
  '02': '0.2',
  '03': '0.1',
  '04': '0.4',
  '05': '0.5',
  '06': '0.6',
  '07': '0.7',
  '08': '0.8',
  '09': '0.9',
  '1': '1'
);

$overflows: (
  'auto': 'auto',
  'visible': 'visible',
  'hidden': 'hidden',
  'scroll': 'scroll',
  'initial': 'initial',
  'overlay': 'overlay',
  'revert': 'revert',
  'unset': 'unset'
);

.d-grid {
  display: grid;
}

@for $i from 1 through 12 {
  @if $i == 2 {
    .grid-#{$i} {
      grid-template-columns: repeat(#{$i}, 1fr);
    }
  } @else if $i == 3 {
    .grid-#{$i} {
      grid-template-columns: repeat(#{$i}, 1fr);
    }
  } @else if $i == 4 {
    .grid-#{$i} {
      grid-template-columns: repeat(#{$i}, 1fr);
    }
  } @else if $i == 5 {
    .grid-#{$i} {
      grid-template-columns: repeat(#{$i}, 1fr);
    }
  }
}

@for $i from 0 through 100 {
  .w-#{$i} {
    width: $i + rem;
  }
  .min-w-#{$i} {
    min-width: $i + rem;
  }
  .max-w-#{$i} {
    max-width: $i + rem;
  }

  .h-#{$i} {
    height: $i + rem;
  }
  .min-h-#{$i} {
    min-height: $i + rem;
  }
  .max-h-#{$i} {
    max-height: $i + rem;
  }

  .left--#{$i} {
    left: -#{$i} + rem;
  }

  .text-size-#{$i} {
    font-size: $i + px;
  }
}

// z-indexes
@for $i from 0 through 100 {
  .z-index-#{$i} {
    z-index: #{$i};
  }
}

// opacity
@each $opacityName, $opacity in $opacities {
  .opacity-#{$opacityName} {
    opacity: #{$opacity};
  }
}

// cursors
@each $cursorName, $cursor in $cursors {
  .cursor-#{$cursorName} {
    cursor: #{$cursor};
  }
}

// spaces
@each $spaceName, $space in $spaces {
  .p-#{$spaceName} {
    padding: #{$space};
  }

  .px-#{$spaceName} {
    padding-left: #{$space};
    padding-right: #{$space};
  }

  .py-#{$spaceName} {
    padding-top: #{$space};
    padding-bottom: #{$space};
  }

  .pl-#{$spaceName} {
    padding-left: #{$space};
  }

  .pr-#{$spaceName} {
    padding-right: #{$space};
  }

  .pt-#{$spaceName} {
    padding-top: #{$space};
  }

  .pb-#{$spaceName} {
    padding-bottom: #{$space};
  }

  .m-#{$spaceName} {
    margin: #{$space};
  }

  .mx-#{$spaceName} {
    margin-left: #{$space};
    margin-right: #{$space};
  }

  .my-#{$spaceName} {
    margin-top: #{$space};
    margin-bottom: #{$space};
  }

  .ml-#{$spaceName} {
    margin-left: #{$space};
  }

  .mr-#{$spaceName} {
    margin-right: #{$space};
  }

  .mt-#{$spaceName} {
    margin-top: #{$space};
  }

  .mb-#{$spaceName} {
    margin-bottom: #{$space};
  }
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.rounded {
  border-radius: 0.75rem;
}

.rounded-full {
  border-radius: 50%;
}

.transition {
  -webkit-transition: all cubic-bezier(0.46, 0.03, 0.52, 0.96) 220ms;
  -moz-transition: all cubic-bezier(0.46, 0.03, 0.52, 0.96) 220ms;
  transition: all cubic-bezier(0.46, 0.03, 0.52, 0.96) 220ms;
}

.img-center {
  background-position: center;
}

.img-cover {
  background-size: cover;
  background-repeat: no-repeat;
}

.break-all {
  word-break: break-all;
}

.break-word {
  word-break: break-word;
}

.organisation-logo {
  overflow: hidden;

  .logo-overlay {
    position: absolute;
    display: none;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.65);
    z-index: 10000;
  }

  &:hover .logo-overlay {
    display: unset;
  }
}

@each $overflowName, $overflow in $overflows {
  .overflow-#{$overflowName} {
    overflow: #{$overflow};
  }
  .overflow-x-#{$overflowName} {
    overflow-x: #{$overflow};
  }
  .overflow-y-#{$overflowName} {
    overflow-y: #{$overflow};
  }
}
