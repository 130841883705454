.app-bar{
    &--timer{
        background-color: rgb(29 37 45 / 25%);
        
        .timer-progress{
            width: 50px;
            height: 50px;
        }
    }
    &--map{       
        z-index: 1;
        width: 100%;
    }
}