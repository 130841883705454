.progress-pill {
  display: grid;
  grid-template-columns: max-content 1fr;
  border-radius: 50rem;
  padding-right: 40px;

  .progress-pill__progress {
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    padding: 0.25rem;
    margin-right: 0.5rem;
  }

  .progress-pill__image {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    box-shadow: 0px 3px 6px #00000029;
    background-color: #ffffff;
  }
  .progress-pill__title,
  .progress-pill__subtitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .progress-pill__title {
    color: #ffffff;
    font-size: 0.825rem;
    display: flex;
    justify-content: flex-start;
    align-content: center;
  }
  .progress-pill__subtitle {
    font-size: 1rem;
    font-weight: bold;
    color: #ff5527;
  }
}

.progress-pill-time-remaining {
  width: 14rem;
  padding-right: 0px !important;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .progress-pill {
    .progress-pill__progress {
      width: 3rem;
      height: 3rem;
    }
  }
}
