#root,
html,
body,
.layout {
  padding: 0;
  margin: 0;
  min-height: 100%;
  min-width: 100%;
  height: 100%;
  overflow: hidden;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.layout {
  display: grid;
}

.rounded-right {
  border-radius: 0 4px 4px 0;
}
