.layout.layout--pre-game {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  background-color: #153961;
  .layout__container {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    overflow-y: auto;
    & > .container {
      background-color: #f4f4f4;
      padding: 1rem;
      max-width: 400px;
      box-sizing: border-box;
    }
    h1 {
      color: $cg-blue;
    }
    .k-button-md {
      line-height: 2;
      font-size: 1rem;
    }
  }
}

@include media-breakpoint-down(md) {
  .layout.layout--pre-game {
    .layout__container {
      overflow-x: auto;
      align-items: start;
      padding: 1rem;
      max-height: 100%;
      margin-top: auto;
      margin-bottom: auto;
      & > .container {
        width: unset;
      }
      .k-button-md {
        line-height: 2;
        font-size: 1rem;
      }
    }
  }
}
