.counter-button {
  .button-left-round {
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    border-top-right-radius: 0%;
    border-bottom-right-radius: 0%;
    width: 25px;
  }

  .button-right-round {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    border-top-left-radius: 0%;
    border-bottom-left-radius: 0%;
    width: 25px;
  }

  .font-size {
    font-size: 15px;
  }

  .counter-value {
    background-color: #cfcfcf;
    border-left: none;
    width: 25px;
  }
}

.k-button-outline-primary {
  color: black;
}
