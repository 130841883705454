.search-input {
}

.search-input__wrapper {
  transition: width linear 0.33s;
  .k-input {
    width: 100%;
  }
  overflow: hidden;
}
.search-input__wrapper--expanded {
  width: 15rem;
}
.search-input__wrapper--collapsed {
  width: 0rem;
}
