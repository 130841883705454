$card-bg-blue-color: #f8fcfe;

.layout.layout--facilitator {
  display: grid;
  grid-template-areas: 'bar bar' 'side-nav main' 'footer main';
  grid-template-columns: min-content 1fr;
  grid-template-rows: min-content 1fr min-content;

  .page-base {
    $header-margin: 1.875rem;
    display: flex;
    flex-direction: column;

    .page-base__header {
      margin: $header-margin $header-margin 0 1.25rem;
      border-bottom: 1px solid $secondary;
      display: flex;
      height: 4.375rem;

      .header__user-card,
      .header__user-icon {
        display: flex;
        margin-left: $header-margin / 2;
        align-items: center;
        border-left: 1px solid $hairline-color;
        padding-left: $header-margin / 2;
        .btn {
          align-items: center;
          display: flex;
          padding: 0;
          color: $subtle-text;
          width: 2.25rem;
          height: 2.25rem;
          justify-content: center;
          box-shadow: none;
        }
      }

      .header__user-card {
        border: 0;
      }

      .header__user-icon .btn {
        border-radius: 50%;
        overflow: hidden;
        object-position: center center;
        object-fit: cover;
        border: 2px solid $cg-green;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .header__user-card {
        flex-direction: column;
        align-items: end;
        .user-card__name {
          color: $cg-green;
        }
        .user-card__org {
          margin-top: -0.5rem;
        }
      }

      .facilitator-logo {
        padding-bottom: 0.75rem;
      }
    }

    main {
      padding: 1.25rem $header-margin $header-margin 1.25rem;
      display: flex;
      flex-grow: 1;
    }
  }

  .app-bar {
    grid-area: bar;
  }

  .app-side-nav {
    grid-area: side-nav;
  }

  .app-main {
    grid-area: main;
    overflow: auto;
  }

  .app-footer {
    grid-area: footer;
  }

  .app-content {
    height: 80vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .bg-card-blue {
    background-color: $card-bg-blue-color;
  }

  .box-rectangular {
    width: 150px;
    height: 150px;
    padding: 10px;
    margin: 0;
    background-color: #f6fcff;
  }

  .box-default-color {
    background-color: #f6fcff;
  }
}

.header__user-menu {
  .list-group-item [class*='material-symbols'] {
    color: $cg-green;
    margin-right: 1rem;
    margin-bottom: 0;
  }
}

.profile-window {
  &.p-0 {
    .k-dialog-content {
      padding: 0;
    }
  }
}

.layout-facilitator--tab {
  display: grid;
  grid-template-areas: 'side-nav main';
  grid-template-columns: min-content 1fr;
  grid-template-rows: min-content 1fr;
  grid-gap: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 85vh;
  .list-facilitator {
    width: 300px;
  }
}

.layout-facilitator-map-container {
  position: relative;

  .bg-transparent {
    background-color: rgba(255, 255, 255, 0.75) !important;
  }

  .player-status {
    color: var(--status-color) !important;
  }

  .map-menu {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.75);
    z-index: 2;
    overflow-y: auto;
  }

  .map-player-location {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 200px;
    background-color: rgba(255, 255, 255, 0.75);
    z-index: 2;
    overflow-y: auto;
  }

  .layout-facilitator--mapview {
    width: 100%;
    height: 100%;
    position: relative;
  }
}

.map-view-option {
  position: absolute;
  bottom: 10px;
  left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  span {
    font-size: 14px;
    margin-right: 5px;
  }
  padding: 5px 10px;
  background-color: #ffffff;
  border-radius: 3px;
}
.ol-attribution {
  ul {
    display: none;
  }
}

.selected-list {
  background-color: #e9e9e9;
}

.rounded-circle {
  border-radius: 50% !important;
  max-width: 100%;
  height: auto;
}

.facilitator-button-light {
  background-color: $light;
  border-color: $dark;
  color: $dark;
}

.facilitator-button-light:hover {
  background-color: $dark;
  border-color: $dark;
  color: $light;
}

.facilitator-button-primary {
  background-color: $primary;
  border-color: $light;
  color: $light;
}

.facilitator-button-primary:hover {
  background-color: $light;
  border-color: $light;
  color: $primary;
}

.k-animation-container {
  z-index: 10003 !important;
}

.facilitator-border-none {
  --bs-list-group-border-color: white !important;
}

.facilitator-tab-button {
  .k-button::after {
    content: '';
    bottom: -5px;
    top: unset;
    color: #ff5527 !important;
    height: 4px;
    opacity: 1;
    border-radius: 0%;
  }
}

.tab-dark-button {
  display: inline-block;
  padding: 10px 20px;
  background: linear-gradient(to bottom, white 90%, $dark 50%);
  background-clip: padding-box, border-box;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  border-radius: 0px;
  overflow: hidden;
  color: $dark;
  border: none;
}

.border-list {
  border: 2px solid;
  border-color: #efefef;
  height: 80vh;
  overflow-y: auto;
}

.group-menu {
  width: 20%;
}

@media (max-width: 767px) {
  .group-menu {
    width: 100% !important;
  }
}

.group-content,
.team-content,
.player-content {
  width: 80%;
}

@media (max-width: 767px) {
  .group-content,
  .team-content,
  .player-content {
    width: 100% !important;
  }
}
