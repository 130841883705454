.achievement-dialog__mask {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(black, 0.5);
  backdrop-filter: blur(2px);
  z-index: 1020; // set higher than sticky class
}

.achievement-dialog {
  width: 35rem;
  height: auto;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  .achievement-dialog__image {
    object-fit: contain;
    object-position: center center;
    width: 12rem;

    height: auto;
    max-width: 100%;
    border-radius: 50%;
    background-color: white;
    padding: 0.25rem;
    box-shadow: 0px 3px 6px #00000029;
    margin-bottom: -6rem;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
    z-index: 1;
  }
  .achievement-dialog__body {
    width: 100%;
    height: 30rem;
    border-radius: $border-radius;
    padding: 6rem 1rem 1rem 1rem;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: radial-gradient(
      closest-side at 50% 50%,
      #003a64 0%,
      #001d32 100%
    );
    box-shadow: 0px 3px 3px #00000065;
    h1,
    h2,
    div {
      padding-bottom: 1rem;
    }
    h1,
    h2 {
      font-weight: bold;
    }
    h1 {
      color: #4698cb;
      padding-top: 1rem;
    }
    h2 {
    }
    p {
      flex-grow: 1;
    }
    div:last-child {
      margin-top: 2rem;
    }
  }
}

.pre-message-dialog__mask {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(black, 0.5);
  backdrop-filter: blur(2px);
  z-index: 1;
}

.pre-message-dialog {
  width: 45rem;
  height: 35rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  .pre-message-dialog__body {
    flex-grow: 1;
    width: 100%;
    height: 100%;
    border-radius: $border-radius;
    padding: 6rem 1rem 1rem 1rem;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: radial-gradient(
      closest-side at 50% 50%,
      #003a64 0%,
      #001d32 100%
    );
    box-shadow: 0px 3px 3px #00000065;
    h1,
    h2,
    div {
      padding-bottom: 1rem;
    }
    h1,
    h2 {
      font-weight: bold;
    }
    h1 {
      color: #4698cb;
      padding-top: 1rem;
    }
    h2 {
    }
    p {
      flex-grow: 1;
    }

    p > span,
    p {
      color: white !important;
      background-color: transparent !important;
    }

    // set the pre-message content max width to its parent
    div:first-child,
    img {
      max-width: 100%;
    }

    // make image keeps its aspect ratio
    img {
      height: auto;
      object-fit: contain;
    }

    // set the container div of button in the bottom
    div:last-child {
      margin-top: auto;
    }
  }
}
