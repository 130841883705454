.splash-image {
  background-color: rgb(255 255 255);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    min-width: 200px;
    max-width: 350px;
    border-radius: 2%;
  }
}
