$overlay-menu-height: 6rem;
$overlay-menu-button-height: 3rem;
$overlay-menu-button-height-mobile: 2rem;
$overlay-footer-height: 6rem;

.assessment-steps-toggle {
  cursor: pointer;
}
.container-menu-button-assessment {
  .k-button--tab {
    width: $overlay-menu-button-height;
    height: $overlay-menu-button-height;
    margin-top: 0.5rem;
    .k-button-text {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: gray;
    }
    svg {
      max-width: 100%;
      height: 2.5rem;
      margin-bottom: 0.25rem;
    }
  }
  .k-button--tab::before {
    content: '';
    bottom: unset;
    top: -4px;
    color: lightgray !important;
    height: 1.5px;
    opacity: 1;
    border-radius: 0%;
  }
  .k-button--tab::after {
    content: '';
    bottom: -4px;
    top: unset;
    color: lightgray !important;
    height: 1.5px;
    opacity: 1;
    border-radius: 0%;
  }
  .k-button--tab-active {
    font-weight: bold !important;
  }
  .k-button--tab-active::after {
    content: '';
    bottom: -5px;
    top: unset;
    color: #ff5527 !important;
    height: 4px;
    opacity: 1;
    border-radius: 0%;
  }
}

.outcome-bg {
  background-color: #f8fcfe;
}

.action-bg {
  background-color: #d1f3e9;
}

.summary-export-card {
  width: 100%;
  height: 150px;
}

@media (max-width: 767.98px) {
  .summary-export-card {
    flex-grow: 1;
    height: 100px;
  }
}
