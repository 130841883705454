$color: rgb(0, 58, 100);

.k-widget.k-chat {
  max-width: 100%;
}

.k-message-group.k-alt {
  .k-bubble {
    background-color: $color !important;
    border: none !important;
  }

  .k-message-time {
    opacity: 0 !important;
  }
}

.k-message-group:last-of-type {
  .k-message-time {
    opacity: 1 !important;
  }
}

.k-message-time {
  font-size: 0.75rem !important;
}

.k-chat {
  .k-alt {
    .k-bubble {
      color: $body-bg;
    }
  }
}

.hide-action {
  .k-message-box {
    display: none;
  }
}

.chat-popup {
  .k-button {
    background-color: #fff;
    border: none;
    justify-content: start;
  }

  .k-button-solid-base:hover {
    border-color: rgb(198.7021276596, 204.9574468085, 213.2978723404);
    background-color: rgb(206.0265957447, 211.4680851064, 218.7234042553);
  }
}

.direct-player-container {
  width: 200px;
  max-width: 200px;
  max-height: 300px;
  overflow-y: auto;
}
