// define opacity manual here becase opacity from kendo theme bootstrap somehow not working
.k-button:disabled {
  opacity: 0.5;
}

.k-button--gradient {
  background: #4698cb;
  background: linear-gradient(127deg, #14bf8b 0%, #4698cb 100%);
}

.k-button--gradient-error {
  background: #4698cb;
  background: linear-gradient(127deg, #fd4343 0%, #4698cb 100%);
}

.k-button--icon .k-button-text {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.btn-icon-center {
  .k-button-text {
    display: inline-flex;
    align-items: center;
  }
}
